.automativeBg{
position: relative;
    .automativeContent{position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: rgba(0, 0, 0, 0.8);color: white; text-align: center;padding: 2em 4em;
        p{font-size: 1.2em;}
    }
}
.pot_txt1{font-weight: bold;}
.reviewCard {
    img{height: 250px;}
}
.accordion_btn{padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem; transition: all 0.5s;}
.faq_box {
    padding: 6px;
}
.panel {
    padding: 0 18px;
    display: none;
    background-color: white;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    padding: 1em;
}
.serviceCard{
    img{height: 160px;}
}
.enquiry_form{background-color: white;}
.text-black{color: black;}
