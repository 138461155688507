 /* Navigation Bar design  */
.dropdown-menu {
    text-align: left;
    background: #636466 !important;
    z-index: 100;
    min-width: 200px;
    border-radius: 0;
    border: 0;
    border-top: 2px solid #ff7b00;
    padding: 0 0px;
    margin: -3px -25px !important;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #ffffff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border-bottom: 1px solid #ff7b00;
}