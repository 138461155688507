.autowatch-result-tbl{
    font-size: 20px;
}
.autowatch-vehicle-info{
    color: #FF7B00;
    font-weight: bold;
    font-size: 23px;
}
.autowatch-text{
  font-size: 20px;
}
.brand-list-style{
  display: inline-block;
    text-align: left;
    padding: 30px;
}

.autowatch-vehicle-info{
  color: white;
  font-weight: bold;
  font-size: 23px;
}
.autowatch-text{
font-size: 20px;
}
.autowatch-vehicle-name{
color: #FF7B00;
}
.box-text-left{
text-align: start;
}
.autoinfo-box{
padding: 15% 10px;
  text-align: start;
  border-radius: 10px;
  background: linear-gradient(360deg,#ffffff,#707070) !important;
  color: #fff;
  text-align: start;
}
.autowatch-box-bottom{
  padding: 50px;
  border-radius: 10px;
}
.auto-result-greybox-status{
  color: #253b83 !important;
}
.brand-list-col{
  column-count: 2;
}
