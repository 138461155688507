@media(max-width:1199px) {}

@media(max-width:991px) {
    .book-tech-input-btn {
        margin-left: 1.7em;
    }

    .sidepanel-part-sm {
        z-index: 1;
    }

    .ecu_makemodel_cont {
        margin: 2em 0;
    }

    .egr-book {
        padding: 10px;
    }

    .open-button {
        z-index: 999;
    }

    .catalytic2 {
        padding-bottom: 1em;
    }

    .book_now_btn {
        width: 100%;
        padding: 15px 20px;
    }

    .box {
        width: 180px;
    }
}

@media(max-width:767px) {
    .abtTeam {
        margin: 1.5em 0;
    }

    .smap_head {
        margin-top: 1em;
    }

    .searchForm {
        max-width: 100% !important;
    }

    /* .homebannerRight img{display: none;} */
    .dont_know {
        color: #323232;
        font-style: italic;
        font-size: 13px !important;
    }

    .desktopTop {
        display: none;
    }

    .mobileTop {
        display: block;
    }

    .pt-100 {
        padding-top: 40px !important;
    }

    .searchbox-btn {
        height: auto !important;
        font-size: 10px;
        padding-top: 14px;
    }

    .banner-area {
        margin-top: -44px;
    }

    .homebannerRight {
        padding-top: 44px;
    }

    .serviceWrapper {
        margin: 1em 0;
    }

    .customerWrapper {
        margin: 1em 0;
    }

    /* .mainTitle{margin-top: 5em;} */

    .test_image2 {
        width: 100%;
        height: auto;
    }

    .customer-benefit-part-sm img {
        margin-top: 1em;
    }

    .reviewCard {
        margin: 1em 0;
    }

    .serviceCard {
        margin: 1em 0;
    }

    .autocard img {
        width: 100%;
        margin: 1em 0;
    }

    .autoImg {
        margin-top: 1.2em;
    }

    .autocard {
        margin: 1em 0;
    }

    .cimg {
        margin: 1em 0;
    }

    .buisnsImg {
        padding-top: 1em;
    }

    .rblogimg {
        margin: 1.5em 0 !important;
    }

    .interest_free_bottom_image {
        height: auto;
        width: 100%;
    }

    .clientImg {
        margin: 1em 0;
    }

    .signupcheck {
        padding: 0 2em;
    }

    .signupcheck label {
        font-size: small;
    }

    .open-button {
        z-index: 999;
    }

    .brochureOverlay {
        padding-top: 15%;
    }

    .popup-heading-enhance {
        font-size: 18px !important;
    }

    .txtCenter {
        text-align: center;
        margin: 0.6em 0;
    }

}

@media(max-width:567px) {
    .homebannerLeft {
        padding-top: 0em;
        padding-left: 20px;
    }

    .test_image2 {
        width: 100%;
    }

    .brnd-img {
        text-align: center;
    }

    .blockquote {
        font-size: 1em;
    }

    .mainTitle h1 {
        font-size: 30px;
    }

    /* .book_now_btn{width: 240px; padding: 10px 32px;text-align: center;} */
    .footer-logo {
        width: 150px;
    }

    .smap_head1 {
        margin-top: 1.5em;
    }

    .marginCustom {
        margin: 0 !important;
    }

    .brkWord {
        word-wrap: break-word;
        word-break: break-all;
    }

    .autowatch-vehicle-info {
        font-size: 12px !important;
    }

    .autoinfo-box {
        padding: 35px 2px;
    }

    .autowatch-vehicle-title {
        font-size: 12px !important;
    }

    .lookup-vehice-service-select-btn {
        width: 100% !important;
    }

    .autowatch-result-tbl th {
        font-size: 12px !important;
    }

    .autowatch-result-tbl {
        font-size: 12px !important;
    }

    .txtCenter {
        text-align: center;
    }

}

@media(min-width:568px) and (max-width: 768px) {
    .lookup-vehice-service-select-btn {
        width: 80% !important;
    }
}

@media (max-width:320px) {
    .top_logo {
        width: 130px !important;
    }

    .brochureOverlay {
        padding-top: 20%;
        text-align: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 20px;
        left: 20px;
    }

    .sidepanel-part-sm a {
        font-size: 12px;
    }

    .booknwimg {
        margin-top: 2em;
    }

    .book_now_btn {
        width: auto;
        padding: 10px 32px;
        text-align: center;
        font-size: 12px !important;
    }

    .smap_head1 {
        margin-top: 1.5em;
    }

    .techDat {
        text-align: center;
    }

    .test_image2 {
        width: 100%;
    }
}

@media only screen and (min-width: 319px) and (max-width: 575px) {
    .homebannerRight {
        margin-left: -10px !important;
    }

    .homebannerRight img {
        width: 75px !important;
    }

    .banner-sm-text1 {
        font-size: 1.2rem !important;
        line-height: 30px !important;
    }

    .input-group {
        margin-top: 0px;
    }

    /*-------------------------- banner desing for mobile 9/may/22------------------- */
    .bannerHead-mobile{
        color: #0B4269;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.2px;
    }
    .bannerServicemobile{
        color: #ff7b00;
        border: 1px solid #ff7b00;
        padding: 7px 5px 7px 5px !important;
        display: inline-block;
        width: -webkit-fill-available;
        text-align: center;
        border-radius: 30px;
        background: #fff !important;
        margin-top: 10px !important;
    }
    #freediagnosisbtn, #dpfdiagnosisbtn, #remappingbtn, #autowatchbtn{
        display: contents;
    }
    .bannerParent-bg{
        background: #134453;
        margin-top: -17px;
    }
    .click-hereBanner{
        color: #ff7b00;
        text-decoration: underline !important;
    }
    .bannerHeadTitle-mobile{
        color: #0B4269 !important;
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 26px !important;
    }
    .bannersubHead-mobile{
        color: #0B4269 !important;
        font-size: 15px !important;
        font-weight: 500 !important;
    }
    .bannersubHead2-mobile{
        margin-top: 20px !important;
        color: #323232 !important;
        font-size: 14px !important;
    }
    /* .homebannerRight .bannerMobile{
        margin-left: 20px !important;
    } */
    .btnGreen1 {
        background-color: #57a40b;
          text-transform: capitalize;
          color: #fff !important;
          border-radius: 0.2em !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          letter-spacing: 0.01rem !important;
      }
}

@media only screen and (min-width: 319px) and (max-width: 360px){
    .homebannerLeft{
        margin-bottom: 30px !important;
    }
    .bannerHeadTitle-mobile{
      color: #0B4269 !important;
      font-weight: 700 !important;
      font-size: 16px !important;
      line-height: 21px !important;
    }
    .bannersubHead-mobile{
      color: #0B4269 !important;
      font-size: 14px !important;
      font-weight: 500 !important;
    }
    .bannersubHead2-mobile{
        margin-top: 15px !important;
        color: #323232 !important;
        font-size: 14px !important;
    }
    #financefree-sm{
        font-size: 10px !important;
    }
}

