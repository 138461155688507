.ajs-dialog {
    background: none transparent;
}

.close_button_popup {
    background-color: #ffffff !important;
    border: 7px solid #ff7b00 !important;
    height: 25px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 25px;
}

@media screen and (max-width: 767px) {
    .close_button_popup {
        background-color: #ffffff !important;
        border: 5px solid #ff7b00 !important;
        height: 25px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 25px;
    }
}

.vehicle_cancel {
    cursor: pointer;
    color: red;
    background: white !important;
    padding: 15px;
}

/* popup enhancement */

.popup-enhance{
    width: fit-content;
    /* width: -webkit-fit-content !important; */
    margin-top: 3%;
    color: black !important;
    background: #fff;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
}

.popup-heading-enhance{
    color: #212121;
    font-weight:500;
    padding: 10px;
    font-size: 23px;
    line-height: 32px;
    text-transform: none !important;
}


.modal-text-white{
    color: rgb(0, 0, 0);
}
.mdlForm .input-mbno, .mdlForm .input-addr{width: 100%; margin-left: 0;}
/*.postcode_number {border: 1px solid black;}*/
.carinfo-box{padding: 20px 35px;}
@media(max-width: 1084px)
{
    .makeBtn{font-size: 15px;}
}
@media (max-width: 991px){
    .sCarMake{margin: 0; padding: 12px;}
}

@media (max-width: 767px){
    .ghost_row2{margin: 0em !important;}
    .btprogress-sm{margin: 1em !important;}
    .btprogress-sm .blockquote{margin-left: 0 !important;}
    .postcode_btn{margin: 0 !important;}
    .postcode_btn .form_input_btn{margin:0.5em 0 !important;width:100% !important;}
    .modal-text-white{margin:1em !important}
    .modal-text-white .mr-5{margin-right: 0.5em !important;}
    .modal-text-white label{font-size: 14px;}
    .popup-heading-enhance {
        font-size: 14px !important;width:100% !important;margin: 0!important;
    }
    .mdlForm .input-mbno, .mdlForm .input-addr{width: 100%;}
    .autoinfo-box{width: 100%; margin-bottom: 1em;}
    .mdlForm{margin-left: 0!important;}
    .enquiry_form-res { padding: 6px !important;}
    .text-res{ font-size: 1.7rem !important; }


}

@media (max-width: 567px){

    .popup-heading-enhance {
        font-size: 12px !important;
    }
    .form_input_text, select, textarea{margin: 6px 0px 16px;}
    .modal-text-white{margin: 0;}
    .progressBox{width: 100%;}
}

@media (max-width: 425px){
    .alertify.ajs-maximized .ajs-commands, .alertify.ajs-resizable .ajs-commands {
        margin: 10px 3px;
    }
    .alertify .ajs-commands{right: 0;}
}

@media (min-width: 426px) and (max-width: 768px){
    .alertify.ajs-maximized .ajs-commands, .alertify.ajs-resizable .ajs-commands {
        margin: 10px 22px;
    }
    .alertify .ajs-commands{right: 0;}
}



@media only screen and (min-width: 992px) {
    .form-control{
        height: 40px !important;
    }
}
.diag-result-page-box{
    border: 1px solid #636466;
    border-radius: 5px;
}

.csr-pointr{
    cursor: pointer;
}

.modal-body {
    padding: 0.5rem !important;
}

#progress-bar-text a {
    font-size: 16px;
    font-style: italic;
    color: #57a40b;
}

@media (max-width: 768px) {
    #progress-bar-text a {
        font-size: 14px;
    }
}
