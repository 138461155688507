.mainTitle{ text-transform: uppercase; text-align:center;margin-bottom:1.8em;
    h1{color: #000000;}
    .borderBottom{height: 1px; width: 200px; background-color: #888888; margin: auto;}
}
.pt-100{padding-top: 60px;}
p{margin-bottom: 0; font-size: 14px; line-height: 1.5; font-family:sans-serif; }

.btnOrange{
    font-weight: bold;border-radius: 5px;   text-transform: uppercase; background-color:  rgb(255, 123, 0); color: white !important;}

.customBreadcrumbs {background-color: #ff7b00;padding: 0.5em 1em;}

.btngreen{ background-color: #57A40B !important;}
.cusreview{width: 150px; height: 150px;}
@media (max-width: 991px)
{
    .infoBtn{width: 100%; margin: 0.5em 0;}
}
@media (max-width: 767px){
    .txtCenter{text-align: center;}
}
@media (max-width: 1023px){
.btngreen{ background-color: #57A40B !important;color: #fff !important;font-size: 0.8rem !important;font-weight: 600;}
}
.btnGreen1 {
    background-color: #57a40b;
      text-transform: uppercase;
      color: #fff !important;
      border-radius: 0.3em;
      font-size: 0.875rem !important;
      font-weight: 600;
      letter-spacing: 0.01rem;
  }

  .srchBtnGreen1{
    background-color: #57a40b !important;
    color: #fff;
    border-radius: 0.3em;
    font-size: 0.875rem;
    padding-bottom: 0.4em;
    font-weight: 600;
    letter-spacing: 0.01rem;
}

// Autowatch result css
.autowatch-result-tbl{
    font-size: 20px;
}
.autowatch-vehicle-info{
    color: #FF7B00;
    font-weight: bold;
    font-size: 23px;
}
.autowatch-text{
  font-size: 20px;
}
.brand-list-style{
  display: inline-block;
    text-align: left;
    padding: 30px;
}

.autowatch-vehicle-info{
  color: white;
  font-weight: bold;
  font-size: 23px;
}
.autowatch-text{
font-size: 20px;
}
.autowatch-vehicle-name{
color: #FF7B00;
}
.box-text-left{
text-align: start;
}
.autoinfo-box{
padding: 5% 10px;
  text-align: start;
  border-radius: 10px;
  background: linear-gradient(70deg,#ffffff,#707070);
  color: #fff;
  text-align: start;
}

.autowatch-box-bottom{
  padding: 40px;
  border-radius: 10px;
}

// how  cc works

.howCCMargin{
    margin-top: 2rem;
}

// -------------------------------------
// popup mobile responsive


@media (max-width: 567px){
  .popup-heading-enhance{
    word-spacing: 0.025rem;
    padding: 0 !important;
    font-size: 1rem !important;
    line-height: 150%;
    font-size: 18px !important;
  }

  .popupTableHead{
    font-size: 0.85rem;
    font-weight: 550;
    letter-spacing: 0.01rem;
  }

  .form_input_btn{
    font-size: 0.9rem !important;
   display: block !important;
  }

  .popupYNRow{
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }

  .popupProgressText{
    margin-left: -1rem !important;
    font-size: 14px !important;
    font-weight: bold !important;
    color: #57A40B !important;
    font-style: italic !important;
  }

  .apiCarResult{
    font-size: 0.8rem;
    color: inherit;
    border-top:0px !important;
    padding: 0px !important;
  }

  .btprogress-sm{
    margin-left: -1rem !important;
  }

  .makeBtn{
    letter-spacing: 0.04rem;
    border-radius: 2rem;
  }


  .postcode_number{
    margin-bottom: 1rem;
    font-size: 0.8rem;
    border-color: #57a40b;
  }

  .popupVType{
    font-size: 0.9rem;
  }

  .popupVMake{
    margin-top: 2rem;
    font-size: 0.9rem;
    margin-bottom: -0.3rem;
  }

  .popupVModel{
    font-size: 0.9rem;
    margin-top: 0.8rem;
    margin-bottom: -0.3rem;
  }

  .popupVEngine{
    margin-top: 1rem;
    font-size: 0.9rem;
  }

  .input_car_search{
    font-size: 0.8rem;
    border: 0px solid #ff7b00;
  }

  .marginCustom{
    margin-bottom: 2rem !important;
    margin-top: 1rem !important;
  }

  .popupSmMargin{
    margin-bottom: 2rem;
  }

  // .popupInfoLabel{
  //   display: none !important;
  // }

  .popupYName{
    font-size: 0.8rem ;
  }

  .popupYEmail{
    font-size: 0.8rem;
   // margin-bottom: -1rem;
    // margin-top: 1.4rem;
  }

  .popupYMobile{
    font-size: 0.8rem;
    // margin-top: -1rem !important;
  }


  .popupCallBack{
    font-size: 0.8rem;
  }

  .popupFaultCode{
    width: 100%;
    font-size: 0.8rem;
  }

  // .callBackDiv{
  //   margin-top: 2rem !important;
  // }

  .faultCodeTxt{
    margin-top: 2rem;
    font-size: 0.8rem;
  }

  .popupRemappMake{
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 170%;
  }

  .popupRemappLabels{
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 170%;
    margin-bottom: 0rem;
    margin-top: 1rem;
  }

  .popupRemappInput{
    border: 1px solid #57a40b;
    font-size: 0.8rem;
  }

  .ccDpfResultText{
    font-size: 0.75rem;
    text-align: left !important;
  }

  .ccDpfResultTextReg{
    font-size: 0.75rem;
    text-align: left !important;
    margin-left: -1.6rem !important;
  }

  .ccDpfResSubHeading{
    font-size: 0.8rem;
    margin-top: -2rem !important;
  }

  .ccDpfRModel{
    margin-top: -1rem !important;
  }

  .ccDpfRContact{
    text-align: left !important;
    font-size: 0.75rem;
  }


  .ccDpfRContactTel{
    text-align: left !important;
    font-size: 0.75rem;
    margin-top: -1rem !important;
  }

  .ccDpfRAgent{
    font-size: 0.8rem;
    text-align: left !important;
  }

  .ccDpfResultlink{
    font-size: 0.8rem !important;
  }

  .ccDpfResultbtn{
    margin-left: 2rem;
    margin-top: -1rem;
  }

  .revimg1{
    margin-top: -1rem !important;
  }

  .popupResultDiagnosis{
    margin-bottom: 0.8rem !important;
    text-align: left !important;
    letter-spacing: 0.03rem;
    word-spacing: 0.05rem;
    padding: 0 !important;
    font-size: 1rem !important;
    line-height: 150%;
  }

}
.remap-mobile-ml{
  margin-left: -5px !important;
}

img{
  border-radius: 5px;
}
.iframe_video {
  border-radius: 15px;
}
.remap-circle-mob{
  margin-bottom: -20px;
}
.remap-circle-mob-text{
  margin-top: -20px;
}
.text-color-orange{
  color: #FF7B00;
}
.text-color-green{
color: #57a40b;
}
