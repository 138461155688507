.linkcolor{
    font-weight: 500;
    font-size: 18px;
    color: #FF7B00 !important;
}

.technician_mobile_loc{
    font-size: 1.4rem;
    font-weight: 600;
  }
  
  .technician_mobile_loc1{
    color: #ff7b00;
    font-weight: 500;
    font-size: 1.2rem;
  }
