.banner-input-search{
background-color: yellow  ;
margin-right: 30px ;
border: 2px solid black !important;
border-radius: 5px ;
font-size: 25px ;
color: black ;
}
.searchForm .customPrepand .input-group-text {
    background-color: blue ;
    padding: 3px ;
    margin-top: -1px;
    border-radius: 5px;
}