.banner-area {
    margin-top: -18px;
    // height: 250%;
}

.homebannerLeft {
    h3 {
        color: white;
    }
}

.homebannerRight {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.home_page_covid_container {
    margin-top: 4%;
}

.searchForm {
    max-width: 75%;
    background-color: #fff;
    border-radius: 50px;
    padding-left: 20px;
    height: 73px;

    .input-group {
        padding: 8px;
    }

    .searchbox-btn {
        padding-top: 10px;
        background-color: #57a40b !important;
        border-color: #57a40b;
        border-radius: 50px;
        width: 100px;
        font-weight: 600;
        text-transform: uppercase;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .customPrepand .input-group-text {
        background-color: white;
        padding: 0.375rem 0.5rem;
        border: none;
    }

    p {
        font-weight: bold;
        font-size: 12px;
        text-align: end;

        a {
            color: rgb(255, 123, 0);
        }
    }
}


.covidWarpper {
    color: white;
    text-align: center;
    padding: 0.9em;
    border-radius: 50px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(1, 0, 36, 1) 0%, rgb(61, 127, 134) 100%);
    h4 {
        color: white !important;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
    }
}

.serviceWrapper {
    text-align: center;
    border-radius: 5px;

    iframe {
        width: 100%;
    }

    p {
        color: #747474;
        font-size: 13px;
        margin-bottom: 0;
    }

    h5 {
        text-transform: capitalize;
        color: #2f4f4f;
        margin-top: 0;
    }

    .serviceContent {
        background-color: white;
        padding-bottom: 20px;
        border-radius: 5px;

        .btn {
            background-color: #57a40b;
            text-transform: uppercase;
            color: white;
            border-radius: 5px;
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.stepWrapper {
    text-align: center;

    .stepImages {
        position: relative;
        height: 90px;
        width: 90px;
        margin: auto;
        margin-bottom: 1em;
    }

    .stepImages img {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        border: 1px solid #747474;
    }

    .stepImages b {
        position: absolute;
        left: 0;
        top: -1px;
        color: white;
        height: 30px;
        font-size: 1.5em;
        background-color: #636466;
        width: 30px;
        padding: 3px;
        border-radius: 50px;
    }
}


.searchWrapper {
    background-color: white;
    border-radius: 4px;
    padding: 2em 3em;

    h6 {
        color: #57a40b;
        text-transform: uppercase;
    }

    .form-check-inline {
        margin-right: 2rem;
    }

    .form-check-label {
        margin-left: 16px;
    }

    .input-group {
        margin-top: 10px;
    }

    .locationWrapper {
        a {
            color: #636466;
            font-size: 14px;
            padding-right: 1em;
        }
    }
}

.customerWrapper {
    text-align: center;
    background-color: white;
    border-radius: 5px;
    padding: 2em;

    img {
        height: 100px;
        width: 100px;
        margin: auto;
        border-radius: 50px;
        border: 1px solid white;
    }
}

.btnGry {
    background-color: rgb(255, 123, 0);
    color: white;
    font-weight: bold;
    font-size: larger;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 1em 0;
}

.btnGry1 {
    background-color: #636466;
    color: white;
    font-weight: bold;
    font-size: larger;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 1em;
}
@media(max-width:1023px){
    .btnGry {
        background-color: rgb(255, 123, 0);
        color: white;
        font-weight: 600;
        font-size: 0.8rem;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 1em 0;
    }
}
.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

// home page mobile responsive

@media(max-width:424px)
{
    .secHeading_sm{
        font-size: 1rem;
    }

    .secsubHeading_sm{
        font-size: 0.9rem;
        font-weight: 550;
    }

    .txtHeading_sm{
        font-size: 0.8rem;
    }

    .normText_sm{
        font-size: 0.75rem;
        font-weight: 500;
    }

    .subText_sm{
        font-size: 0.65rem;
    }

    .btnGreen1 {
        background-color: #57a40b;
          text-transform: uppercase;
          color: #fff !important;
          border-radius: 0.3em;
          font-size: 0.8rem;
          font-weight: 500;
      }

}


@media(max-width:424px){
    .getAgent_sm{
        margin-top: 0.4em;
        margin-bottom: 0.4em;
    }

    .btnTextSrch_sm{
        font-size: 0.8rem;
        font-weight: 500;
    }

    .srchBtnGreen1{
        background-color: #57a40b !important;
        color: #fff;
        border-radius: 0.3em;
        font-size: 0.7rem;
        padding-top: 0.5em;
        font-weight: 550;
    }

    .paymentSub_sm{
        font-size: 0.65rem;
        margin-top: 0.5em;
        margin-bottom: 2em;
    }

    .paymentSubColor_sm{
        color: #ff7b00;
    }

    .businessHead_sm{
        text-align: center;
        font-size: 1rem;
        margin-bottom: -1.5em !important;
        margin-top: -1em;
    }
    .businessImg_sm{
        margin-bottom: 5em;
    }

    .customerSec_sm{
        margin-top: -2em;
        margin-bottom: -4em;
        font-size: 1rem;
    }

    .homeReviewBtn_sm{
        margin-top: 1em !important;
    }

    .spaceHeading_sm{
        letter-spacing: 0.05em;
    }

    .contentAlign_sm{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .textAlign_sm{
        text-align: center;
    }

    .btnLearnAbout{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0rem;
        margin-top: 1rem !important;
    }

    .imgResponsive_sm{
       max-width: 100%;
       height: auto;
       margin-top: -1rem;
    }
}

// homepage 3 service radio buttons
.home-radiobtn{
    width: 15px;
    height: 15px;
    border-radius: 0%;
}
.home-service-slct{
    cursor: pointer;
}
.three-service-section{
    margin-bottom: -40px;
}

.breadcrumb{
    margin-top:-3rem;
    height: 28px;
    border-radius: 0;
    background-color: #134453;
}

@media(max-width:992px){
    .breadcrumb{
        margin: -3rem -1rem 1rem -1rem;
        height: 28px;
        border-radius: 0;
        background-color: #134453;
    }
}

