
.top_logo{width: 150px;}
.enquiryBtn{border-radius:0; border-right:1px solid rgba(0,0,0,0.05)!important;text-transform:uppercase; font-weight: bold; color: #134453; font-size:14px;
.fa-envelope:before{background-color: transparent; color: #57a40b; font-size: 16px;}
.fa-phone{background-color: transparent; color: rgb(255, 123, 0); font-size: 16px;padding: 6px;}
.fa-calendar:before{background-color: transparent; color: #57a40b; font-size: 16px;}
}
.desktopTop{display: block;}
.mobileTop{display: none;}
@media(max-width:767px){
    .desktopTop{display: none;}
    .mobileTop{display: block;}
    .fa-envelope:before, .fa-phone, .fa-calendar:before{font-size: 20px;}
}
  /* // main search box input box */
  .licenseplate {
    color: black;
    background-color: yellow;
    background-size: 2.3rem 2.9rem;
    background-repeat: no-repeat;
    font-family: 'Segoe UI', Arial, sans-serif;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding-left: 10px;
    padding-bottom: 4px;
    width: 240px !important;
    border: 3px solid black;
    border-radius: 10px;
    outline: none;
  }
  .licenseplate :placeholder {
  color: black;
  }
  .searchForm {
    max-width: 55% !important;
    background-color: #fff;
    border-radius: 50px;
    padding-left: 20px;
    height: 67px !important;
  }
  .go-btn{
    z-index: 99 !important;
    border-bottom-left-radius: 15px !important;
    border-top-left-radius: 15px !important;
    width: 75px !important;
    height: 50px;
    padding-top: 13px !important;
  }
  /* // main search box input end */
