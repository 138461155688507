/* .float-booknow-sm{
    position: fixed;
    bottom: 5px;
    left: 20px;
    z-index: 999;
} */
/* .my-float{
    width:60px;
    height:60px;
} */
.float-booknow-sm {
    position: fixed;
    bottom: 15px;
    font-size: 12px;
    left: 10px;
    z-index: 999;
    font-weight: 700;
    color: white;
    background-color: #0c9cd9;
    padding: 3px 8px;
    border-radius: 5px;
    box-shadow: 2px 2px 3px #525252;
}