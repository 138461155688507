.addrsTxt{font-size: 16px;}
.query-input {
    padding-left: 20px;
    height: 40px;
    width: 90%;
    border-radius: 5px;
    margin: 10px;
    border: 1px solid #e1e1e1;
    background-color: white;
}

