@import "~bootstrap/dist/css/bootstrap.css";
@import '~@fortawesome/fontawesome-free/css/all.min.css';
//@import '~animate.css/animate.min.css';
//@import '~slick-carousel/slick/slick.css';
//@import '~slick-carousel/slick/slick-theme.css';
//@import '~jquery-colorbox/example1/colorbox.css';
@import "style.css";
@import "about.css";
@import "home.scss";
@import "comman.scss";
@import "header.scss";
@import "automative.scss";
@import "blog.scss";
@import "contact.scss";
@import "sitemap.css";
@import "responsive.css";
@import "~alertifyjs/build/css/alertify.min.css";
@import "modal_popup.css";
@import "services.css";
@import "brand_images.css";
@import "agent_short_info.css";
@import "autowatch_result.css";
@import "floating-booknow-btn.css";
@import "main_search_box.css";
@import "nav-bar.css";
@import '~jquery-ui/themes/base/all.css';
