.badge-success{
    color: #57a40b;
    background-color: #fff;
    border: 2px solid #57a40b;
}

.btn-agent-short{
    background-color: #ff7b00;
    color: #fff;
    border-radius: 0.7rem;
}

.fb-agent-short{
    color: #57a40b;
    font-weight: 550;
    font-size: 1rem;
}