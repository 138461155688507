.shadow_services {
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.19);
}

.catalytic1 {
    color: #FF7B00;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
}

.catalytic2 {
    font-size: 1rem;
    font-weight: 500;
}

.catalytic3 {
    background-color: #FF7B00;
    color: #fff;
    font-size: 1rem;
    font-weight: 550;
}

.catalytic5 {
    background-color: #FF7B00;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 15px;
    margin-top: 60px;
    width: 300px;
    height: 60px;
}

.catalytic6 {
    background-color: #FF7B00;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 550;
    border-radius: 15px;
    margin-top: 10px;
    width: 300px;
    height: 60px;
}

.dirty_valve1 {
    color: #FF7B00;
    font-weight: 600;
}

.price_color {
    color: #FF7B00;
}

.dirty_btn1 {
    background-color: #FF7B00;
    color: #fff;
    font-size: 1.1rem;
    font-weight: 550;
    border-radius: 15px;
    margin-top: 40px;
    width: 320px;
    height: 80px;
}

/* ----all location---- */
.all_loc {
    color: #ff7b00;
    font-weight: 600;
    font-size: 1.5rem;
    letter-spacing: 1px;
}

@media (max-width: 991px) {
    .catalytic2 {
        padding-bottom: 1em;
        padding-top: 1em;
    }

    .dirty_btn1 {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .catalytic5 {
        width: 100%;
        margin: 1em 0;
    }

    .catalytic6,
    .catalytic3 {
        width: 100%;
    }
}

@media(max-width: 768px) {
    .popup-enhance {
        width: 90%;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        font-size: 24px;
    }
}
